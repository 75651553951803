<template>
  <div class="policy_box">
    <!-- 运输条款 -->
    <div v-if="tabType === '1'">
      <p class="text_content">
        1、本条款的主体是您与广东一站网络科技有限公司（以下简称一站网）。<span class="fz26 f333 fb"
          >请您认真阅读本条款，您使用本服务表明您已充分阅读、理解并接受本条款的全部内容，并确认您因工作需要使用本服务时，您已充分获得您工作单位的授权。</span
        >
      </p>
      <p class="text_content">
        2、本条款履行过程中如发生争议，双方应友好协商解决。如协商不成的，应向一站网所在
        地有管辖权的人民法院提起诉讼。
      </p>
      <p class="text_content">
        3、<span class="fz26 f333 fb"
          >您同意遵守本公司的隐私政策及不时修订的内容，同意一站网按照法律法规和隐私政策的规定处理您提供的运单信息。</span
        >
      </p>
      <p class="text_content fb">4、<span class="fz26 f333 fb">理赔规则</span></p>
      <p class="text_content">
        您理解并认同，一站网是按托寄物的重量或体积收取运费，并非按托寄物的价值和预期收益
        收取运费，本着公平合理的原则，您同意以下理赔规则：
      </p>
      <p class="text_content">
        4.1 <span class="fz26 f333 fb">下列情形本公司不承担赔偿责任：</span>
      </p>
      <p class="text_content">
        （1）<span class="fz26 f333 fb under_line"
          >因地震、台风、洪灾、火灾、暴雨雪、泥石流、战争、暴乱、疫情、政府管制等不可抗力导致货物延误、灭失、破损、短少的；</span
        >
      </p>
      <p class="text_content">
        （2）<span class="fz26 f333 fb"
          >一站网无法拆开托寄物原包装进行内包装加固，运输时原包装无损的；</span
        >
      </p>
      <p class="text_content">
        （3）<span class="fz26 f333 fb"
          >您基于托寄物可能获得的收益、实际用途、商业机会等任何间接损失；</span
        >
      </p>
      <p class="text_content">
        （4）<span class="fz26 f333 fb">其他法律规定或双方约定本公司免于赔偿的。</span>
      </p>
      <p class="text_content">
        （6）<span class="fz26 f333 fb">外包装完好，而内件缺少或损坏;</span>
      </p>
      <p class="text_content">
        （7）<span class="fz26 f333 fb">签收时未在签收面单备注破损，少件，淋湿等异常。</span>
      </p>
      <p class="text_content">
        （8）<span class="fz26 f333 fb"
          >不论是否有代收货款服务的，因收寄双方或者相关第三方主张权利等原因托寄物被采取保全措施（如因未付款扣留托寄物等）而造成的损失;</span
        >
      </p>
      <p class="text_content">
        （9）<span class="fz26 f333 fb"
          >因可托寄的物品违反限寄或禁寄规定，被国家行政机关没收或依照有关法律法规处理的;</span
        >
      </p>
      <p class="text_content">
        （10）<span class="fz26 f333 fb"
          >如客户与我司签订有其他书面协议（包括但不限于《托寄物免赔协议》、《易损易碎品运输协议》等），以相关协议约定为准;</span
        >
      </p>
      <p class="text_content">
        （11）<span class="fz26 f333 fb">所有裸包装货物（轮胎除外），属于保丢不保损范围;</span>
      </p>
      <p class="text_content">
        （12）<span class="fz26 f333 fb"
          >仪器、设备类等货物，只有塑料薄膜包装（外包装未打木架）的视为裸装货物，属于保丢不保损范围;</span
        >
      </p>
      <p class="text_content">
        （13）<span class="fz26 f333 fb"
          >所有不符合运输行业包装标准的货物，属于保丢不保损范围;</span
        >
      </p>
      <p class="text_content">
        4.2
        <span class="fz26 f333 fb">未保价货物</span
        >在运输环节发生灭失、破损、短少的，一站网在3倍运费的限额内向您赔偿托寄物的实际损失，双方另有约定的除外。<span
          class="fz26 f333 fb"
          >如您认为该赔偿标准不足以弥补您的损失，应根据托寄物的实际价值进行保价。</span
        >
      </p>
      <p class="text_content">4.3 <span class="fz26 f333 fb">保价服务</span></p>
      <p class="text_content">
        4.3.1 一站网提供的保价服务，系基于您的保价金额收取费用，并基于保价金额、托寄物实
        际价值、受损比例、实际损失等因素综合考虑赔偿，请您如实按照托寄物实际价值进行保价。
      </p>
      <p class="text_content">
        4.3.2 一站网对托寄物价值采取“理赔审查”的方式，<span class="fz26 f333 fb"
          >您应在寄件时如实按照托寄物的实际价值诚信保价，理赔时您应当提供托寄物的真实有效价值证明（如发票，合同、付款凭证组合），一站网会结合市场价值和第三方机构意见评估损失价值，不足额保价部分或超额保价部分均无法获得赔偿。如您无法证明托寄物品的实际价值，一站网将全额退还您的保价费用，按照未保价快件进行赔偿。</span
        >
      </p>
      <p class="text_content">
        4.3.3 保价货物在运输环节损坏的，对于可以维修的托寄物，一站网在保价金额的限额内赔
        偿维修费用。经一站网认可的鉴定评估机构认定托寄物全损，或维修费用高于托寄物实际价值的，一站网按照货物灭失进行赔偿。
      </p>
      <p class="text_content">
        4.3.4 保价货物在运输环节全部灭失的，一站网按照实际损失向您赔偿，但最高不超过保价
        金额；货物部分灭失的，一站网按照全部灭失赔偿标准乘以损失比例向您赔偿。您或指定付款人拒绝支付保价费用的，一站网将按照未保价货物进行赔偿。
      </p>
      <p class="text_content">
        4.3.5 托寄物损坏残值由一站网和您协商处理，如归您所有，一站网在核定赔偿金额时将扣 减残值。
      </p>
      <p class="text_content">
        4.3.6 因一站网与保险公司针对托寄物存在合作投保的情况，如托寄物出现损坏、遗失等异
        常状况时，可能由该等保险公司直接负责相关的理赔事宜。为保障您的个人权益，您的寄件信息、托寄物信息等个人信息将会同步至与一站网合作的保险公司，并由该等保险公司处理您的托寄物理赔事宜。如您签订本协议，即视为同意一站网将您的上述个人信息同步至与一站网合作的保险公司并同意由该等保险公司用于处理托寄物理赔事宜。
      </p>
      <p class="text_content">
        4.4
        索赔的时间要求和处理。除非与相关适用法律冲突，任何索赔必须在一站网接收托寄物后的30天内以书面形式向一站网提出并附上相应证明材料，否则一站网将不再承担任何责任。若所有费用尚未被支付，本公司无义务受理任何索赔，且索赔金额不可用该应付费用抵消。若收件人在签收托寄物时没有注明有任何损坏，则该托寄物被视为完好送达。出于核查理赔真实性的需要，本公司有权对原托寄物和包装材料进行检查。若您或收件人拒绝配合，一站网对该索赔申请将不予以审查和承担赔偿责任。
      </p>
      <p class="text_content">
        4.5 保价费的计算方式为保价费=保价声明价值*保价费率。保价费率双方另有约定的，则以 约定为准。
      </p>
      <p class="text_content">
        5、<span class="fz26 f333 fb">为符合法律法规对托寄物安全的要求</span
        >，保证托寄物安全送达，您在寄件时应履行以下义务：
      </p>
      <p class="text_content">
        5.1
        <span class="fz26 f333 fb"
          >确保托寄物不包含国家机关公文、国家保护野生动物和濒危野生动物及其制品、假冒伪劣和侵权物品等法律法规禁止和限制寄递的物品</span
        >，不得通过寄递渠道危害国家安全、公共安全和公民、法人、其他组织的合法权益。
      </p>
      <p class="text_content">
        5.2
        <span class="fz26 f333 fb">配合进行开箱验视</span
        >。托寄物需要办理审批、检验等手续的，您应提交办理完相关手续的证明文件。一站网可以对托寄物内件拍照取证，如发现禁止或限制寄递的物品，有权移交相关部门处理。
      </p>
      <p class="text_content">
        5.3
        <span class="fz26 f333 fb">如实申报托寄物的名称、性质、数量和价值</span
        >，并准确填写发货人、收货人的名称、地址、联系电话等信息。
      </p>
      <p class="text_content">
        5.4根据托寄物的性质（尤其是易碎品、液体、气体），<span class="fz26 f333 fb"
          >提供充分的防破损、防漏、防爆措施，保障托寄物安全派送。</span
        >
      </p>
      <p class="text_content">6、<span class="fz26 f333 fb">您的违约赔偿责任：</span></p>
      <p class="text_content">
        6.1 因托寄物有安全隐患、质量缺陷或包装破损，致使其他托寄物、运输工具、机械设备被
        污染腐蚀、损坏，或造成人身伤亡的，您应承担赔偿责任。
      </p>
      <p class="text_content">
        6.2 因托寄物属于或含有禁止或限制寄递物品而被查没、扣留或变更配送路线，导致其他托
        寄物时效延误或价值丧失，给一站网或第三人造成经济损失的，您应承担赔偿责任。
      </p>
      <p class="text_content">
        6.3 因您违反第6条的义务导致一站网或第三人承担行政处罚、民事赔偿等所造成的各项经
        济损失，您应承担赔偿责任。
      </p>
      <p class="text_content">7、<span class="fz26 f333 fb">留置权</span></p>
      <p class="text_content">
        若您或您指定的付款人未付清费用的，一站网有权留置托寄物，留置后您的债务履行期限为
        2个月（<span class="fz26 f333 fb">自揽收快件之日起计算</span
        >），但托寄物性质不能留置的除外。<span class="fz26 f333 fb"
          >债务履行期限届满，您或指定付款人仍未付清费用的，一站网可以变卖、拍卖留置物并优先受偿。变卖、拍卖所得价款应优先支付一站网为行使留置权所支出的费用和保管费，再偿付拖欠一站网的费用；如有余额，可根据您的要求汇至您指定账户</span
        >。
      </p>
      <p class="text_content">8、<span class="fz26 f333 fb">关于时效的约定，</span></p>
      <p class="text_content">
        8.1
        <span class="fz26 f333 fb">您周日发送托寄物的运输时效会</span
        >自动加一天，该时间经过系统计算并自动加入到小程序上您所看到的小程序展示的时效内。
      </p>
      <p class="text_content">
        8.2
        <span class="fz26 f333 fb"
          >国家法定节假日发送的货物的运输时效会自动顺延至法定节假日结束后的第一天开始计算</span
        >，这个时效在系统内没有计算进入至小程序展示的时效内。如果您对时效要求严格，您可以提前至法定节假日前发货。
      </p>
      <p class="text_content">9、<span class="fz26 f333 fb">关于费用的约定</span></p>
      <p class="text_content">
        9.1 无法派送的托寄物，若您要求退回，则双程费用均由您承担。
      </p>
      <p class="text_content">
        9.2 若您要求退回，则双程费用均由您承担；若您对快件声明放弃的，您不可就该快件再向
        一站网主张任何权利，您同意一站网有权自行处置该托寄物，一站网不对此承担任何责任。
      </p>
      <p class="text_content">
        10、<span class="fz26 f333 fb"
          >一站网会以电话、电子邮件、手机短信等方式告知、发送的产品/业务介绍、推广促销信息及其他商业性信息。您不同意继续接收以上信息的，可以通过短信或拨打一站网客服热线方式退订。</span
        >
      </p>
      <p class="text_content">
        11、上述条款未涉及事宜，按照国家相关法律法规及标准执行。
      </p>
      <p class="text_content">
        12、您确认，您已充分阅读、理解并接受本条款的全部内容。即便本条款中的某条款被法院
        或政府机构认定为无效或者部分无效的，您也同意除无效条款外仍继续执行其他条款。
      </p>
      <p class="text_content">
        13、为反映有关法律法规的变化以及一站网服务内容的变动，一站网可能会不时修订或更新
        本服务条款，并将修订或更新版本内容在本页面发布，请点击本服务条款时仔细阅读，服务条款及不时修订或更新版本内容自发布之日生效。
      </p>
    </div>
    <!-- 隐私政策 -->
    <div v-else-if="tabType === '2'">
      <p class="text_content">
        本《隐私政策》介绍了广东一站网络科技有限公司及其关联公司（以下统称“一站网”）所收集的消费者（“您”）个人
        信息类型和收集方式，信息的使用方式和范围，信息的披露，信息的跨境转移，以及一站网为保护该等个人信息所采取
        的措施。此外，通过本《隐私政策》，您可知晓如何要求一站网：(1)查询、更改和删除一站网所保存的与您相关的个人
        信息；(2)停止向您发送特定短信；(3)处理您就一站网的隐私事项提出的投诉等。<span
          class="fz26 f333 fb"
        >
          在您使用一站网的产品或服务前，请务必仔细阅读本《隐私政策》，尤其是加粗的条款。当您使用一站网网站、软件应用或其他产品或服务，或者在本政策更新后继续使用一站网的产品或服务，即视为您已经阅读并同意本《隐私政策》。</span
        >
      </p>
      <p class="text_content_fb">一、一站网所收集的信息和收集方式</p>
      <p class="text_content_fb">
        （一）一站网可能会在下列活动中，获得您的个人信息：
      </p>
      <p class="text_content">1、使用一站网网站和应用；</p>
      <p class="text_content">
        2、货运活动，包括交付和领取货物、使用一站网的增值服务（如保价）；
      </p>
      <p class="text_content">3、查询货物或咨询；</p>
      <p class="text_content">4、问卷调查；</p>
      <p class="text_content">5、促销和其他优惠活动。</p>
      <p class="text_content_fb">（二）我们如何收集和使用您的个人信息</p>
      <p class="text_content">
        <span class="fz26 f333 fb">个人信息</span
        >是指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。
      </p>
      <p class="text_content">
        <span class="fz26 f333 fb">个人敏感信息</span
        >是指一旦泄露、非法提供或滥用可能危害人身和财产安全，极易导致个人名誉、身心健康受到损害或歧视性待遇等的个人信息。<span
          class="fz26 f333 fb"
          >本政策中涉及的个人敏感信息，将采用加粗字体突出显示。</span
        >
      </p>
      <p class="text_content">
        一站网仅会出于本政策所述的以下目的，收集和使用您的个人信息：<span class="fz26 f333 fb"
          >我们可能会收集、保存和使用下列与您有关的信息才能实现下述功能。如果您不提供相关信息，您将无法享受相关的一站网服务。这些功能包括：</span
        >
      </p>
      <p class="text_content_fb">1、注册账号</p>
      <p class="text_content">
        为完成创建账号，您需提供以下信息（统称“账户信息”）：您的手机号、账号名称/昵称、账号密码，我们将通过发送短信验证码来验证您的身份是否有效；您还可以补充您的生日、性别、地区，您补充的信息将有助于我们为您提供优质的服务体验，如果您不提供这些补充信息，不会影响您使用一站网服务的基本功能。
      </p>
      <p class="text_content_fb">2、为您提供物流服务</p>
      <p class="text_content" decode="true">
        在您下单寄件或您的信息作为收货人被提供时，一站网或一站网通过其授权的业务合作伙伴收集您的以下信息：发货人及收货人的姓名、地址、<span
          class="fz26 f333 fb"
          >联系方式</span
        >和货物信息（统称“运单信息”），用于提交订单、生成物流运单、收货、分拣、仓储、运输、出口清关、进口清关、以及派送货物；根据相关法律要求，我们还收集您的<span
          class="fz26 f333 fb"
          >身份证件信息</span
        >用于核验您的身份和进出口报关。<span class="fz26 f333 fb"
          >如果您提供了不准确或错误的地址，在不违反相关法律法规对个人信息存储的强制要求的前提下，我们可能会使用您过往的运单信息对不准确、错误的地址信息进行补全、纠正，以节省与发/收货人反复确认地址的时间，保证时效，避免您因货物错误派送、延迟派送而遭受损失，让您有更好的服务体验。<br />此外，在为您提供收派服务的过程中，如涉及电话沟通，我们可能会对通话内容进行录音，以避免潜在纠纷。</span
        >
      </p>
      <p class="text_content_fb">3、支付结算</p>
      <p class="text_content">
        在您下单后，您可以选择一站网关联方或与一站网合作的第三方支付机构（如一站网金融、支付宝、微信支付以及银联、网联等支付通道，以下称“支付机构”）所提供的支付服务，我们需要将您的一站网订单号与<span
          class="fz26 f333 fb"
          >交易金额信息</span
        >提供给这些支付机构以确认您的支付指令并完成支付。<br />如您申请发票，还需提供以下信息：发票抬头、纳税人识别号、<span
          class="fz26 f333 fb"
          >收票人邮箱地址</span
        >，以及您根据您的开票需求提供的更多信息，如购买方信息。
      </p>
      <p class="text_content_fb">4、消息推送</p>
      <p class="text_content">
        我们收集您的设备信息，用于向您推送货物路由信息、支付状态信息及优惠券、促销信息。您可以在手机或其他设备的“设置”或在APP、微信公众号等进行免打扰设置。
      </p>
      <p class="text_content_fb">5、客服与售后</p>
      <p class="text_content">
        我们的客服和售后功能会使用您的账号信息和运单信息。<br />为保证您的账号安全，我们的呼叫中心客服和在线客服会使用您的<span
          class="fz26 f333 fb"
          >账号信息、运单信息</span
        >核验您的身份。当您需要我们提供与您的货物相关的客服与售后服务时，我们将会查询您的运单信息、货件路由信息。您有可能在与我们的客服人员沟通时，提供上述信息外的其他信息，如当您要求我们变更派送地址、联系人或<span
          class="fz26 f333 fb"
          >联系电话</span
        >。<br />此外，我们还会收集您参与问卷调查时向我们提交的问卷答复信息、您在一站网网站上提交“合作咨询”所提供的联系人姓名、公司名称、<span
          class="fz26 f333 fb"
          >联系电话、邮箱地址</span
        >及您的咨询信息。
      </p>
      <p class="text_content_fb">6、改善我们的产品或服务</p>
      <p class="text_content">
        <span class="fz26 f333 fb"
          >我们可能会将收集的您的账户信息、运单信息进行数据分析以形成用户画像，向您提供更优质的服务。</span
        >对于从您的各种设备上收集到的信息，我们可能会将它们进行关联，以便我们能在这些设备上为你提供一致的服务。我们可能会将来自某项服务的信息与来自其他服务的信息、合法间接获取的信息及合法公开渠道获取的信息结合，或者将合法获取的您的可识别个人信息与无法识别个人的信息结合，为您提供优质服务及改善我们的产品和服务。
      </p>
      <p class="text_content_fb">7、保障交易安全</p>
      <p class="text_content">
        为提高您使用我们的服务时系统的安全性，更准确地预防钓鱼网站欺诈和保护账户安全，我们可能会通过了解您的<span
          class="fz26 f333 fb"
          >浏览信息、运单信息</span
        >、您常用的软件信息、设备信息等手段来判断您的账号风险，并可能会记录一些我们认为有风险的链接（“URL”）；我们也会收集您的<span
          class="fz26 f333 fb"
          >设备信息</span
        >（如<span class="fz26 f333 fb">设备识别码</span>）和<span class="fz26 f333 fb">微信ID</span
        >（针对从微信端使用一站网服务的用户）对于一站网系统问题进行分析、统计流量并排查可能存在的风险、在您选择向我们发送异常信息时予以排查。
      </p>
      <p class="text_content_fb">（三）间接获取、使用个人信息的情形</p>
      <p class="text_content">
        我们可能从第三方获取您授权共享的<span class="fz26 f333 fb">账户信息</span
        >（头像、昵称），并在您同意本政策后将您的第三方账户与您的一站网账户绑定，使您可以通过第三方账户直接登录并使用一站网的服务。<br />在您向他人寄件时，请确保已获得收货人的相关授权，确保一站网有权依据本政策处理收货人的个人信息。<br />如我们从我们的合作伙伴获取您的个人信息，我们将依据与第三方的约定、对个人信息来源的合法性进行确认后，在符合相关法律和法规规定的前提下，使用您的这些个人信息。
      </p>
      <p class="text_content_fb">（四）其他用途</p>
      <p class="text_content">
        1、<span class="fz26 f333 fb"
          >一站网可能会以电子邮件、手机短信等方式向您发送一站网认为您可能会感兴趣的广告和其它商业信息。</span
        >
      </p>
      <p class="text_content">
        2、我们通过技术手段对收集的您的个人信息进行去标识化处理后，去标识化处理的信息将无法识别主体，您知悉并同意，我们有权使用已经去标识化的信息。在不透露您个人信息的前提下，<span
          class="fz26 f333 fb"
          >我们有权对用户数据库进行分析并予以商业化的利用。</span
        >
      </p>
      <p class="text_content">
        3、我们会对我们服务使用情况进行统计，并可能会与公众或第三方共享这些统计信息，以展示我们的服务的整体使用趋势。但这些统计信息不包含您的任何身份识别信息。
      </p>
      <p class="text_content">
        4、当我们展示您的个人信息时，我们会采用包括内容替换、匿名处理方式对您的信息进行脱敏，以保护您的信息安全。
      </p>
      <p class="text_content">
        5、当我们要将您的个人信息用于本政策未载明的其它用途时，或基于特定目的收集而来的信息用于其他目的时，会通过在线点击授权或邮件、短信等形式事先征求您的同意。
      </p>
      <p class="text_content_fb">（五）征得同意的例外</p>
      <p class="text_content">
        您充分知悉，在以下情形中，我们收集、使用个人信息无须征得您的授权同意：
      </p>
      <p class="text_content">1、与国家安全、国防安全有关的；</p>
      <p class="text_content">2、与公共安全、公共卫生、重大公共利益有关的；</p>
      <p class="text_content">3、与犯罪侦查、起诉、审判和判决执行等有关的；</p>
      <p class="text_content">
        4、出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；
      </p>
      <p class="text_content">
        5、所收集的个人信息是个人信息主体自行向社会公众公开的；
      </p>
      <p class="text_content">
        6、从合法公开披露的信息中收集的您的个人信息的，如合法的新闻报道、政府信息公开等渠道；
      </p>
      <p class="text_content">7、根据您的要求签订和履行合同所必需的；</p>
      <p class="text_content">
        8、用于维护所提供的服务的安全稳定运行所必需的，例如发现、处置服务的故障；
      </p>
      <p class="text_content">9、为合法的新闻报道所必需的；</p>
      <p class="text_content">10、法律法规规定的其他情形。</p>
      <p class="text_content">
        根据法律规定，共享、转让经去标识化处理的个人信息，且确保数据接收方无法复原并重新识别个人信息主体的，不属于个人信息的对外共享、转让及公开披露行为，对此类数据的保存及处理将无需另行向您通知并征得您的同意。
      </p>
      <p class="text_content_fb">二、信息的使用方式和范围</p>
      <p class="text_content">1、领取、交付和跟踪货件；</p>
      <p class="text_content">
        2、提供与物流、快件等核心和附加业务相关的产品和服务（如保险服务）；
      </p>
      <p class="text_content">3、处理和收取款项；</p>
      <p class="text_content">
        4、就您的要求、问题和意见，向您提供客户支持、向您做出回应并与您沟通；
      </p>
      <p class="text_content">
        5、建立和管理您的一站网账户（如月结账号、一站网官网、触屏官网、一站网app，大客户发件、空运发货、集中录单，整车，订单管理账号等）；
      </p>
      <p class="text_content">
        6、以电话、电子邮件、手机短信等方式向您告知、发送一站网认为您会感兴趣的广告和其它商业信息；
      </p>
      <p class="text_content">
        7、沟通和管理您参与调查、抽奖和其他优惠或促销活动的资格；
      </p>
      <p class="text_content">8、应您的要求向您的联系人发送信息；</p>
      <p class="text_content">9、处理就一站网的服务所收到的申诉</p>
      <p class="text_content">
        10、经营、评估和改善一站网的业务（包括开发新产品和服务，管理一站网的通信，判断一站网的销售、营销和广告效果，分析和强化一站网的产品、服务、网站和应用，和开展会计、审计、记账、对账）；
      </p>
      <p class="text_content">
        11、进行数据分析（包括市场和消费者调查、趋势分析、财务分析）；
      </p>
      <p class="text_content">
        12、避免、识别和防止欺诈以及其他犯罪活动、申诉和其他法律责任遵从适用的法律规定和一站网的政策；
      </p>
      <p class="text_content">
        13、定制用户对一站网网站和应用的访问，通过用户浏览一站网网站和应用的方式提供根据用户兴趣定制的内容（包括广告）；
      </p>
      <p class="text_content">
        14、管理一站网的业务。一站网可能会用您所在场所的相关信息（如，您的邮政编码或邮递区号）来补充一站网通过自动方式收集的数据，以便向您提供您可能感兴趣的内容。
      </p>
      <p class="text_content">
        15、帮助诊断技术和服务问题、管理一站网的网站和应用、识别一站网网站和应用用户的身份和收集有关一站网用户的人口统计信息。
      </p>
      <p class="text_content">
        16、利用点击流数据，判断使用模式以及一站网定制网站和应用的方式，以便更好地满足用户需求。
      </p>
      <p class="text_content">
        17、一站网还会以收集时具体告知的其他方式，使用所获得的与您相关的信息
      </p>
      <p class="text_content_fb">
        三、我们如何共享、转让、公开披露您的个人信息
      </p>
      <p class="text_content_fb">（一）共享</p>
      <p class="text_content">
        1、我们不会与一站网以外的任何公司、组织和个人分享您的个人信息，但以下情况除外：
      </p>
      <p class="text_content">
        （1）在获取明确同意的情况下共享：获得您的明确同意后，我们会与其他方共享您的个人信息；
      </p>
      <p class="text_content">
        （2）我们可能会根据法律法规规定，或按政府主管部门的强制性要求，对外共享您的个人信息；
      </p>
      <p class="text_content">
        （3）在法律法规允许的范围内，为维护一站网或合作伙伴、您或其他一站网用户或社会公众利益、财产或安全免遭损害而有必要提供；
      </p>
      <p class="text_content">
        （4）只有共享您的信息，才能实现我们的服务的核心功能或提供您需要的服务；
      </p>
      <p class="text_content">（5）应您需求为您处理您与他人的纠纷或争议；</p>
      <p class="text_content">
        （6）符合与您签署的相关协议（包括在线签署的电子协议）或其他的法律文件约定所提供；
      </p>
      <p class="text_content">（7）基于学术研究而使用；</p>
      <p class="text_content">（8）基于符合法律法规的社会公共利益而使用。</p>
      <p class="text_content">
        2、与我们的关联公司共享：您的个人信息可能会与一站网的关联公司共享。我们只会共享必要的个人信息，且受本政策中所声明目的的约束。关联公司如要改变个人信息的处理目的，将再次征求您的授权同意。<br />我们的关联公司是指：不时控制一站网或其实体、或被一站网或其实体所控制、或与一站网或其实体被共同控制之下的任何实体，其中“控制”一词意指直接或间接具备或控制50%以上投票权。
      </p>
      <p class="text_content">
        3、与授权合作伙伴共享：仅为实现本政策中声明的目的，我们的某些服务将由授权合作伙伴提供。我们可能会与合作伙伴共享您的某些个人信息，以提供更好的客户服务和用户体验。例如，我们为您提供保险服务时，我们可能与保险服务提供商共享您的个人信息以安排合作伙伴提供服务。我们只会共享提供服务所必要的个人信息，我们的合作伙伴无权将共享的个人信息用于任何其他用途。
      </p>
      <p class="text_content">目前，我们的授权合作伙伴包括以下类型：</p>
      <p class="text_content">
        （1）供应商、服务提供商和其他合作伙伴。我们将信息发送给在全国范围内支持我们业务的供应商、服务提供商和其他合作伙伴，这些支持包括提供技术基础设施服务、分析我们服务的使用方式或潜在风险、广告服务、运输、仓储、清关、末端收派、支付便利、提供客户服务、提供数据存储或数据分析服务、提供第三方附加业务功能服务（如语音输入、地图功能）或进行学术研究和调查。
      </p>
      <p class="text_content">
        （2）我们的关联公司与我们适用同样的数据合规管理制度、本政策以及相关保密和安全措施。对我们与之共享个人信息的我们的关联公司之外的公司、组织和个人，我们会与其签署严格的保密协议/数据处理协议，要求他们按照我们的说明、本政策以及其他任何相关的保密和安全措施来处理个人信息。
      </p>
      <p class="text_content_fb">（二）转让</p>
      <p class="text_content">
        我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：
      </p>
      <p class="text_content">
        1、在获取明确同意的情况下转让：获得您的明确同意后，我们会向其他方转让您的个人信息；
      </p>
      <p class="text_content">
        2、在涉及合并、收购或破产清算时，如涉及到个人信息转让，我们会在要求新的持有您个人信息的公司、组织继续受本政策的约束，否则我们将要求该公司、组织重新向您征求授权同意；
      </p>
      <p class="text_content">
        3、符合与您签署的相关协议（包括在线签署的电子协议）或其他的法律文件约定所提供；
      </p>
      <p class="text_content">
        4、根据适用的法律法规、法律程序的要求、强制性的行政或司法要求所必须的情况进行提供。
      </p>
      <p class="text_content_fb">（三）公开披露</p>
      <p class="text_content">我们仅会在以下情况下，公开披露您的个人信息：</p>
      <p class="text_content">1、获得您明确同意后；</p>
      <p class="text_content">
        2、基于法律的披露：在法律、法律程序、诉讼或政府主管部门强制性要求的情况下，我们可能会公开披露您的个人信息。
      </p>
      <p class="text_content_fb">四、退订</p>
      <p class="text_content">
        您有可能会收到一站网发送的核心和附加业务相关的营销信息，可以在收到营销信息时，根据信息中的退订提示（如“回复TD退订”），要求一站网不再向您发送营销信息，一站网会尊重您的请求。
      </p>
      <p class="text_content_fb">五、查询和更正</p>
      <p class="text_content">
        您可以使用您在一站网（如一站网官网、触屏官网、一站网app，大客户发件、空运发货、集中录单，整车，订单管理）的账号和密码登陆您所注册的一站网相关网站或应用，查询您注册时向一站网提供的个人信息。您还可与我们联系，以查询一站网所保存的、与您相关的个人信息。为保护您的隐私安全，一站网将在授权您查询该等信息前验证您的身份。如果您认为一站网所保存的、与您相关的该等个人信息不准确，您可以直接在您所注册的相关网站或应用修改该等信息，您还可与我们联系，要求一站网更正或修改该等信息。
      </p>
      <p class="text_content_fb">六、一站网如何保护个人信息</p>
      <p class="text_content">
        一站网会在管理、技术和物理上维持保护措施，以保护您所提供的个人信息，使其免受意外、非法或未经授权的破坏、损失、更改、存取、披露或使用。尽管一站网采取相应的安全措施来保护您的信息，但请您理解任何安全措施都不是无懈可击的。<br />请注意，单独的设备信息、搜索关键词信息等是无法与任何特定个人直接建立联系的数据，不属于个人信息。如果我们将这类非个人信息与其他信息结合用于识别自然人个人身份，或者将其与个人信息结合使用，则在结合使用期间，此类设备信息将被视为个人信息。
      </p>
      <p class="text_content_fb">七、本《隐私政策》的更新</p>
      <p class="text_content">
        我们的隐私政策可能变更。<br />
        未经您明确同意，我们不会削减您按照本政策所应享有的权利。我们会在本页面上发布对本政策所做的任何变更。
        对于重大变更，我们还会提供更为显著的通知（包括对于某些服务，我们会通过电子邮件或短信发送通知，说明隐私政
        策的具体变更内容）。<br />
        本政策所指的重大变更包括但不限于：
      </p>
      <p class="text_content">
        1、我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；
      </p>
      <p class="text_content">
        2、我们在所有权结构、组织架构等方面发生重大变化。如业务调整、破产并购等引起的所有者变更等；
      </p>
      <p class="text_content">
        3、个人信息共享、转让或公开披露的主要对象发生变化；
      </p>
      <p class="text_content">
        4、您参与个人信息处理方面的权利及其行使方式发生重大变化；
      </p>
      <p class="text_content">
        5、我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时；
      </p>
      <p class="text_content">6、个人信息安全影响评估报告表明存在高风险时。</p>
      <p class="text_content_fb">八、条款独立性</p>
      <p class="text_content">
        如果本《隐私政策》任何条款或者部分根据适用法律被宣告或判决违反法律、无效或不可执行，则该违反法律、无效或不可执行并不影响本《隐私政策》其他条款的效力，本《隐私政策》可依照删除该等违法、无效或不可执行的条款后解释。
      </p>
      <p class="text_content_fb">九、未成年人的个人信息保护</p>
      <p class="text_content">
        1、一站网非常重视对儿童个人信息的保护。若您是14周岁以下的儿童，在使用我们的产品与/或服务前，应事先取得您监护人的同意。一站网根据国家相关法律法规的规定保护儿童的个人信息。
      </p>
      <p class="text_content">
        2、我们不会主动直接向儿童收集其个人信息。对于经监护人同意而收集儿童个人信息的情况，我们只会在受到法律允许、监护人同意或者保护儿童所必要的情况下使用、共享、转让或披露此信息。
      </p>
      <p class="text_content">
        3、如果有事实证明儿童并未取得监护人同意的情况下注册使用了我们的产品与/或服务，我们会与相关监护人协商，并设法尽快删除相关个人信息。
      </p>
      <p class="text_content">
        4、对于可能涉及的不满14周岁的儿童个人信息，我们进一步采取以下措施予以保障：
      </p>
      <p class="text_content">
        （1）对于收集到的儿童个人信息，我们除遵守本隐私政策关于用户个人信息的约定外，还会秉持正当必要、知情同意、目的明确、安全保障、依法利用的原则，严格遵循《儿童个人信息网络保护规定》等法律法规的要求进行存储、使用、披露，且不会超过实现收集、使用目的所必须的期限，到期后我们会对儿童个人信息进行删除或匿名化处理。我们会指定专人负责儿童个人信息保护事宜，您也可以通过客服热线400-868-5656与我们联系。我们还会制定儿童个人信息保护的内部专门制度。
      </p>
      <p class="text_content">
        （2）当您作为监护人为被监护的儿童选择使用一站网相关服务时，我们可能需要向您收集被监护的儿童个人信息，用于向您履行相关服务之必要。在具体服务中需要向您收集儿童个人信息的，我们会事先取得您的授权同意，并告知您收集的目的和用途。如果您不提供前述信息，您将无法享受我们提供的相关服务。此外，您在使用晒单、评价及问答功能分享服务相关信息时可能会主动向我们提供儿童个人信息，请您明确知悉并谨慎选择。您作为监护人应当正确履行监护职责，保护儿童个人信息安全。若儿童本人需要注册或使用我们的产品与/或服务，您应正确引导并予以监护。
      </p>
      <p class="text_content">
        （3）儿童或其监护人有权随时访问和更正儿童个人信息，还可以向我们提出更正和删除的请求。如您对儿童个人信息相关事宜有任何意见、建议或投诉、举报，请联系我们。我们会随时为您提供帮助。
      </p>
      <p class="text_content_fb">十、免责条款</p>
      <p class="text_content">
        为给您提供便利和更多信息，一站网的网站和应用可能会包含非一站网站点的链接。该等网站直接收集的信息受其自身的隐私政策保护。一站网还可能通过第三方平台（如应用商店供应商）或其他第三方渠道向您提供服务。对任何通过非一站网站点与渠道收集信息的隐私保护，一站网不承担责任。<br />第三方应用、工具和插件（如微博、QQ、微信、支付宝等第三方登录服务）直接收集的信息将受第三方服务供应商隐私政策的保护，一站网对此不承担任何责任。
      </p>
      <p class="text_content_fb">十一、投诉和联系我们</p>
      <p class="text_content">
        如果您对本隐私政策有任何疑问、意见或建议，可通过以下方式与我们联系：<br />
        广东一站网络科技有限公司<br />
        电话：400-868-5656<br />
        注册地址：广州市天河区龙口东路363号3楼自编316房<br />
        常用办公地址：广州市天河区龙口东路363号3楼自编316房<br />
        一般情况下，我们将在十五天内回复。<br />
        如果您对我们的回复不满意，特别是我们的个人信息处理行为损害了您的合法权益，您还可以通过以下外部途径寻求解
        决方案：向您所在国家的监管部门进行投诉或举报，例如中国的网信、电信、公安及工商等监管部门。
      </p>
    </div>
    <div v-else-if="3">
      <div class="fz30 fb">一、公路产品收货标准</div>
      <div class="span_title">1、普货</div>
      <div class="span_content">
        对运输、装卸、保管无特殊要求，常规车辆可运输的，不是危险品或者冷冻品以及保鲜的，不超高超宽的货物。
      </div>
      <div class="span_title">2、货品负面清单</div>
      <div class="span_title flex_m"><span class="circl-doit bg-000 mr30"></span> 拒收品</div>
      <div class="span_content">
        拒收品定义：我司不具备运输条件或收运容易给公司带来损失的货物
      </div>
      <div class="border f000 tc fb pt10 pb10 mt20">《拒收品明细表》</div>
      <div class="flex">
        <span class="border f000 flex_c_m tab-left">工艺品类</span>
        <span class="border flex1 tab-right"
          >铁艺物品、铜艺物品、花艺物品、骨雕物品、木雕物品、石雕物品、竹雕物品、炭雕物品、树脂类工艺品、古董字画（上述物品单票保价在5000元以上的拒收）</span
        >
      </div>
      <div class="flex">
        <span class="border f000 flex_c_m tab-left">家具机器类</span>
        <span class="border flex1 tab-right"
          >钢琴、红木家具（用紫檀木，花梨木、香枝木、黑酸枝、红酸枝、乌木、条纹乌木、鸡翅木制成的家具）、金丝楠木家具、摩托车汽车电动车整件</span
        >
      </div>
      <div class="flex">
        <span class="border f000 flex_c_m tab-left">食品类</span>
        <span class="border flex1 tab-right"
          >新鲜的肉、新鲜的水产品、新鲜的蛋类、速冻食品或者活物</span
        >
      </div>
      <div class="flex">
        <span class="border f000 flex_c_m tab-left">不确定价值</span>
        <span class="border flex1 tab-right"
          >有效证件、车牌、文件、技术资料、公章、合同、发票、游艇、玉雕物品、象牙雕刻物品、各种土壤样本；贵重金属</span
        >
      </div>
      <div class="flex">
        <span class="border f000 flex_c_m tab-left tc">LED类、液晶类、等离子类</span>
        <span class="border flex1 tab-right"
          >包装不合规的，不收取包装要求：包装内部需用塑料泡沫等进行内部填充，外部包装需用纸箱和木架材料保障货物稳定性</span
        >
      </div>
      <div class="flex">
        <span class="border f000 flex_c_m tab-left">易碎物品类</span>
        <span class="border flex1 tab-right"
          >纯玻璃制品（灯具除外）、亚克力板（有机玻璃）、含玻璃的家具（玻璃面积超过家具最大面面积30%）、大理石类物品、陶瓷类货物</span
        >
      </div>
      <div class="flex">
        <span class="border f000 flex_c_m tab-left">特殊类</span>
        <span class="border flex1 tab-right"
          >1、有毒、有害、易燃、易爆、易污染货物； 2、需冷藏冷冻或防晒等特殊条件保管的物品；
          3、国家明令禁止或限制运输的货物，如烟草制品、枪支弹药、放射性物资、危险品、贵重金属等</span
        >
      </div>

      <div class="span_title flex_m"><span class="circl-doit bg-000 mr30"></span> 特殊规范品</div>
      <div class="span_content mb10">
        1、特殊品定义：我司可以收取的货物，但货物需符合对应的收货规范
      </div>
      <div class="span_content">2、收货规范明细表</div>
      <div class="flex mt20">
        <span class="border f000 flex_c_m tab-left">货物类型</span>
        <span class="border flex1 tab-right tc fb pt10 pb10">收货规范</span>
      </div>
      <div class="flex">
        <span class="border f000 flex_c_m tab-left">收货规范</span>
        <span class="border flex1 tab-right"
          >1、酒类没有自身发酵产生气体或者外部充入的气体，如啤酒、气泡酒不可运输，金属罐装除外；
          2、箱装的瓶装或罐装酒类，内部单瓶为含有缓冲物的独立包装，可以收取；
          3、箱装内部单瓶没有含缓冲物的独立包装，需用缓冲物将瓶体缠好，四周用缓冲物固定，以防止瓶体间的碰撞和瓶体与箱体的碰撞，然后封装；或通过木架包装后进行运输；
          4、坛装或塑桶装酒需打木架包装后进行运输；
          5、酒类货物需在外包装上黏贴酒类易碎品标签，搬运及装卸过程中严禁倒置，一个托盘中有多件酒类货物时注意货物固定，防止倾倒；
        </span>
      </div>
      <div class="flex">
        <span class="border f000 flex_c_m tab-left tc"
          >玻璃制品（仅限生活器皿类、含玻璃仪器/表、不含玻璃面）</span
        >
        <span class="border flex1 tab-right"
          >包装要求： ①生活器皿(玻璃杯、茶具，烟灰缸、相框等玻璃制品)
          A、货物本身有缓冲物：针对缓冲物为泡沫等与生活器皿紧密贴合、按照生活器皿形状定制缓冲物的纸箱包装，可以收取；主动向客户推广打木架、打木箱等包装等服务，安全更佳；
          B、货物本身无缓冲物：没有含缓冲物的独立包装，需用缓冲物将瓶体缠好，四周用缓冲物固定，以防止瓶体间的碰撞和瓶体与箱体的碰撞，然后封装；或通过木架包装后进行运输；
          ②含玻璃仪器（非玻璃精密仪器）
          A、客户自行包装：整体需用珍珠棉、气泡膜等缓冲物缠绕，保证货物表面不裸露在外（玻璃部分需重复加缠绕膜固定），外部再加纸箱后打木架/木箱；
          B、我司代打木架：营业部/司机需对货物整体用珍珠棉、气泡膜等缓冲物缠绕，保证货物表面不裸露在外（玻璃部分需重复加缠绕膜固定），外部再加纸箱后，方可装载至外场进行打木架/木箱。
        </span>
      </div>
      <div class="flex">
        <span class="border f000 flex_c_m tab-left">卫浴产品</span>
        <span class="border flex1 tab-right"
          >1、包装要求： ① 钢化玻璃类（如淋浴房）：填充缓冲物后用纸箱包装并打木架；
          ② 陶瓷类（如马桶、浴缸）：缠膜后用气泡膜、塑料泡沫等进行内部填充，外面包装纸箱并固定打包带，最后打上木架；
          ③ 五金类（如龙头、五金挂件）：缠膜后放置在纸箱中并固定打包带；
          ④ 木制类（如木柜、镜柜）：用防震泡沫板等包围四周，间隙用珍珠棉袋等填充，外部纸包装后打木架；
          注：客户自己包装，需要核实是否满足我们公司包装要求，不满足需要进行二次包装。
        </span>
      </div>
      <div class="span_title flex_m"><span class="circl-doit bg-000 mr30"></span> 违禁品</div>
      <div class="span_content mb10">
        法律、行政法规明令禁止或限制运输的货物；
      </div>
      <div class="span_content mb10">
        有腐蚀性、有放射性等可能危及运输工具、人身和财产安全的货物；
      </div>
      <div class="span_content mb10">
        易燃违禁品：以燃烧、爆炸为主要特性的压缩气体和液化气体、易燃液体、易燃固体、自燃物品和遇湿易燃以及达到一定温度会产生冒烟、自燃、爆炸等强烈化学反应的物品。
      </div>
      <div class="border f000 tc fb pt10 pb10 mt20">常见违禁品明细表</div>
      <div class="flex">
        <div class="border f000 flex_c_m tab-left2 tc">国家法定违禁品</div>
        <div class="flex1">
          <div class="flex">
            <span class="border f000 flex_c_m tab-left3 tc">枪支弹药警械类</span>
            <span class="border flex1 tab-right"
              >武器及其部件、各种类型的军用枪支、民用枪支、运动枪、猎枪、信号枪、麻醉注射枪、仿真武器、炸弹、手榴弹、子弹、照明弹、教练弹、烟幕（雾）弹、信号弹、毒气弹、炸药、引信、雷管、导火索及其他爆炸物品、纵火器材。</span
            >
          </div>
          <div class="flex">
            <span class="border f000 flex_c_m tab-left3 tc">管制刀具类</span>
            <span class="border flex1 tab-right"
              >匕首、三棱刀、三棱刮刀、半圆刮刀、侵刀、扒皮刀、羊骨刀、猎刀、弹簧刀、单刃及双刃尖刀（刀体八厘米以上，带自锁装置或非折叠式）、武术用刀(能开刃的)、武术用剑（能开刃的）、少数民族用的藏刀、腰刀、靴刀、弓、弩（备注：若无法区分为何种刀具，则刀刃超过25厘米的一律不可收运）</span
            >
          </div>
          <div class="flex">
            <span class="border f000 flex_c_m tab-left3 tc">毒品类</span>
            <span class="border flex1 tab-right"
              >鸦片、吗啡、大麻、可卡因、可待因、海洛因、摇头水、罂粟、杜冷丁 、古柯
              、冰毒、K粉、咖啡因、三唑仑、安钠咖、氟硝安定、麦角乙二胺（LSD）、安眠酮、丁丙诺啡、地西泮、鼻吸剂、开单品名或货物包装中含有“可卡因、可待因、曲马多”字样。</span
            >
          </div>
          <div class="flex">
            <span class="border f000 flex_c_m tab-left3 tc">麻醉药品类</span>
            <span class="border flex1 tab-right"
              >阿片粉、复方桔梗散、阿片酊、盐酸吗啡注射液、盐酸乙基吗啡、盐酸罂粟碱、福尔可定及其片剂、盐酸可卡因、全阿片素、大麻浸膏、杜冷丁注射液、杜冷丁片、安侬痛注射液、枸椽酸芬太尼注射液、美散痛注射液、美散痛片、曲马多</span
            >
          </div>
          <div class="flex">
            <span class="border f000 flex_c_m tab-left3 tc">高价值类</span>
            <span class="border flex1 tab-right"
              >所有国家货币（现金等）、具有现金同等功能的纪念币、有价证券、伪造的国家货币及有价证券、黄金、白银、金箔、珍贵文物、古董、珠宝、玉器、钻石。</span
            >
          </div>
          <div class="flex">
            <span class="border f000 flex_c_m tab-left3 tc">禁运读物及音制品</span>
            <span class="border flex1 tab-right"
              >对中国政治、经济、文化、道德有害的，内容涉及国家机密，带有违反“一个中国”原则的所有印刷品及照片；淫秽、迷信、盗版类的印刷品；赌博设备及器具（具有押分退分、退币、退钢珠等赌博功能游艺设备）、光碟、磁带、录像带。</span
            >
          </div>
          <div class="flex">
            <span class="border f000 flex_c_m tab-left3 tc">烟草及动植物皮毛类</span>
            <span class="border flex1 tab-right"
              >香烟（无准运证，符合3.5.6可以承运除外）、烟叶或烟丝（无准运证）、走私品、尸骨（包括已焚化的尸骨）、未经硝制或药制的兽皮兽骨、尸体、动植物活体、未经处理的动物皮毛。</span
            >
          </div>
          <div class="flex">
            <span class="border f000 flex_c_m tab-left3 tc">易燃易爆物品</span>
            <div class="flex1">
              <div class="flex">
                <span class="border f000 flex_c_m tab-left4 tc">电池</span>
                <span class="border flex1 tab-right">所有蓄电池类货物</span>
              </div>
              <div class="flex">
                <span class="border f000 flex_c_m tab-left4 tc">易燃标示</span>
                <span class="border flex1 tab-right"
                  >有易燃易爆标志的货物、带文字说明的易燃易爆货物</span
                >
              </div>
              <div class="flex">
                <span class="border f000 flex_c_m tab-left4 tc">爆炸物品</span>
                <span class="border flex1 tab-right"
                  >1、爆破器材：如炸药、雷管、导火索、导爆索、爆破剂等。
                  2、烟花爆竹：如烟花、鞭炮、摔炮、拉炮、砸炮、彩药弹等烟花爆竹及黑火药、烟火药、发令纸、引火线等。
                  3、其他：如推进剂、发射药、硝化棉、硝化纤维胶片、电点火头等。</span
                >
              </div>
              <div class="flex">
                <span class="border f000 flex_c_m tab-left4 tc">压缩和液化气体及其容器</span>
                <span class="border flex1 tab-right"
                  >1、易燃气体：如氢气、甲烷、乙烷、丁烷、天然气、液化石油气、乙烯、丙烯、乙炔、打火机等。
                  2、易爆或者窒息、助燃气体：如干冰、压缩氧气、氮气、氦气、氖气、气雾剂等。
                  3、所有气压、液压瓶罐（封口的空瓶罐）。</span
                >
              </div>
              <div class="flex">
                <span class="border f000 flex_c_m tab-left4 tc">易燃液体</span>
                <span class="border flex1 tab-right"
                  >酒精、显影液、化油器清洗剂、抛光液、稀释液、汽油、柴油、煤油、桐油、丙酮、乙醚、生漆、苯、酒精、松香油、硝化甘油、香蕉水、天那水、易燃油漆、含油金属屑、含动植物油的油棉纱油纸及其制品等。</span
                >
              </div>
              <div class="flex">
                <span class="border f000 flex_c_m tab-left4 tc"
                  >易燃固体、自燃物质、遇水易燃物质</span
                >
                <span class="border flex1 tab-right"
                  >1、易燃固体：如红磷、硫磺、铝粉、闪光粉、固体酒精、火柴、活性炭、打火石、含油金属屑等。
                  2、自燃物质：如黄磷、白磷、硝化纤维（含胶片）、钛粉、活性炭等。
                  3、遇水易燃物质：如金属钠、钾、锂、钛粉、锌粉、镁粉、碳化钙（电石）、氰化钠、氰化钾等。</span
                >
              </div>
              <div class="flex">
                <span class="border f000 flex_c_m tab-left4 tc">其他</span>
                <span class="border flex1 tab-right"
                  >灭火器、染料（叔丁胺、多晶硅、间二甲苯、硫化氢、噻吩、对甲氧基乙酰苯胺、对氯硝基苯、环已胺等染料成分）、救生器、其他属于国家易燃易爆品。</span
                >
              </div>
            </div>
          </div>
          <div class="flex">
            <span class="border f000 flex_c_m tab-left3 tc">化学危险品</span>
            <span class="border flex1 tab-right"
              >氧化剂、发光剂、过氧化钠、过氧化钾、硝酸铵、过氧化铅、亚硫酸氢钠、压缩气体、过氧乙酸、双氧水、氟乙酸甲酯、生化物品、传染性物品、硫酸、硝酸、盐酸、氢氧化钾、氢氧化钠、强碱、农药（中等毒及以上）、炭疽、传染性细菌、生物培养基、带有活病原体的物质、来自疫区的食品、医疗废物、化学废物、工业废物、疫苗、血液制品、人体器官、诊断样品、尿样。</span
            >
          </div>
          <div class="flex">
            <span class="border f000 flex_c_m tab-left3 tc">放射性物品类</span>
            <span class="border flex1 tab-right"
              >具有ą、β、γ射线和中子流的货物及金属，如镭、铀、钚、锕和核能物品。</span
            >
          </div>
        </div>
      </div>
      <div class="span_content mt20">
        除以上标出品名货物外，外包装或货物本身含有以下标志的货物也禁止收取，如外包装有危险品标识，须证明内物不是危险品，方可收取；如证明不了，则按照违禁品处理。
      </div>
      <div class="mt30 fz30 fb">二、铁路/水路产品收货标准</div>
      <div>
        <div class="span_content flex_m mt20">
          1、禁止装载国家明文禁止危险物品，包括锂电池、涂料。
        </div>
        <div class="span_content flex_m">2、普运40尺箱不能装载瓷砖。</div>
        <div class="span_content flex_m">3、装载过程需至少拍照6张留存：</div>
        <div class="span_content flex_m ml20">
          1）装载前从后面拍能看到箱号整体空箱。
        </div>
        <div class="span_content flex_m ml20">2）开始装载时；</div>
        <div class="span_content flex_m ml20">3）装载至中部;</div>
        <div class="span_content flex_m ml20">4）装载完成全开门；</div>
        <div class="span_content flex_m ml20">5）装载完成后半开门；</div>
        <div class="span_content flex_m ml20">6）全关门。</div>
        <div class="span_content flex_m">
          4、20尺柜每次发运数量原则上不能少于2个或为2个的倍数。
        </div>
        <div class="span_content flex_m"></div>
      </div>
      <div class="mt30 fz30 fb">三、航空产品收货标准</div>
      <div>
        <div class="span_content flex_m mt20">1、禁止收寄</div>
        <div class="span_content flex_m">
          禁止收取易燃、易爆、易腐蚀、有毒、放射性、液体、膏状、粉末类物品及违法物品。
        </div>
        <div class="span_content flex_m">2、限制收寄</div>
        <div class="span_content flex_m ml10">
          1）窄体机尺寸控制在1*1*1米以内，南航单重量限重80公斤，深航单重限重120公斤.宽体机尺寸控制在3*1.5*1.5米以内
        </div>
        <div class="span_content flex_m ml10">
          2）化工类：除日用化工品外(如牙膏、洗面奶、洗洁精、化妆品)，其它化工类物品需提供安全属性证明材料，确认安全后，将证明材料随货寄出。若需要通过航空发运的，需提供《鉴定报告书》在发运机场报备通过后方可发运航空。
        </div>
        <div class="span_content flex_m ml10">3）电池类</div>
        <div class="span_content flex_m ml20">①航空收寄标准：</div>
        <div class="span_content flex_m ml30">
          检测报告：必须提供符合UN38.3测试和1.2米跌落试验相关检查报告；
        </div>
        <div class="span_content flex_m ml30">
          报备：提供检测报告原件向机场安检报备通过后方可发运；
        </div>
        <div class="span_content flex_m ml30">
          包装：必须为商业包装，每台电子产品最多配2块电池，且机电分离，电池必须独立密封包装；机电一体的电子产品须关机。
        </div>
        <div class="span_content flex_m ml20">②电池禁收：</div>
        <div class="span_content flex_m ml30">
          禁止收取摩托车、汽车、电动车(含电动单车、三轮车、平衡车)的蓄电池，禁止收取额定能量大于100WH(瓦/小时)的锂电池或充电宝。
        </div>
        <div class="flex_lr_m mt20 mb10">
          <img width="63%" src="../../assets/images/rules/contraband_left.jpg" />
          <img width="37%" src="../../assets/images/rules/contraband_right.jpg" />
        </div>
        <img width="100%" src="../../assets/images/rules/contraband_more.jpg" />
      </div>
    </div>
    <div class="policy_foot tc">
      <span class="mainbtn" @click="returnPrevPage">{{
        tabType === '1' || tabType === '2' ? '同意' : '确定'
      }}</span>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      tabType: '1',
      path: '',
      allDatas: null
    }
  },
  methods: {
    returnPrevPage() {
      let typeName = this.path === 'm_batchorder' ? 'allBatchDatas' : 'oneDatas'
      //返回上一页
      this.$router.push({
        name: this.path,
        params: {
          [typeName]: this.allDatas,
          isAgree: this.tabType === '1' || this.tabType === '2' ? true : false
        }
      })
    }
  },
  created() {
    let routeParams = this.$route.params
    this.tabType = routeParams.tabType || ''
    this.path = routeParams.path || ''
    this.allDatas = routeParams.allDatas || ''
  }
}
</script>
<style scoped>
.policy_box {
  background: #fff;
  font-size: 28px;
}
.circl-doit {
  width: 15px;
  height: 15px;
  border-radius: 50%;
}
.bg-000 {
  background-color: #333;
}
.border {
  border: 1px solid #ddd;
}
.tab-left {
  width: 200px;
  font-weight: bold;
}
.tab-right {
  padding: 5px 10px;
}
.tab-left2 {
  width: 50px;
}
.tab-left3,
.tab-left4 {
  width: 150px;
}
</style>
